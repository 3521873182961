import { Controller } from "@hotwired/stimulus";
import { DataTable } from "simple-datatables";

export default class extends Controller {
  static targets = ["table"];

  tableTargetConnected(element) {
    const datatable = new DataTable(element, {
      searchable: true,
      perPageSelect: false,
      sortable: false,
      labels: {
        placeholder: "Search...",
        perPage: "{select} entries per page",
        noRows: "No results match your search query",
        info: "Showing {start} to {end} of {rows} entries",
      },
      footer: true,
      firstLast: true,
    });

    this.updateFooter(element, datatable);

    // Listen for table updates (search, sort, pagination)
    ["datatable.sort", "datatable.search", "datatable.page"].forEach((event) => {
      datatable.on(event, () => this.updateFooter(element, datatable));
    });
  }

  updateFooter(element, datatable) {
    const footer = element.querySelector("tfoot");
    if (footer) {
      footer.style.display = "table-footer-group"; // Ensure visibility
    }

    console.log("Updating Footer"); // Debugging
    this.calculateTotals(datatable);
  }

  calculateTotals(datatable) {
    const columnIndexes = [9, 10, 11, 12, 13, 14, 15, 16, 17]; // Adjust as needed
    console.log("Calculating Totals for columns:", columnIndexes);

    columnIndexes.forEach((colIndex) => {
      let total = 0;

      // Get all rows (not just the visible ones)
      const allRows = datatable.body.querySelectorAll("tr");

      allRows.forEach((row) => {
        const cellText = row.cells[colIndex]?.textContent.trim() || "0";
        console.log(`Column ${colIndex}:`, cellText);
        total += this.parseNumber(cellText);
      });

      const footerCell = datatable.wrapper.querySelector(`tfoot tr th:nth-child(${colIndex + 1})`);
      if (footerCell) {
        footerCell.textContent = this.formatCurrency(total);
        console.log(`Updated Footer Column ${colIndex}:`, this.formatCurrency(total));
      }
    });
  }

  parseNumber(value) {
    return parseFloat(value.replace(/[^0-9.-]+/g, "")) || 0;
  }

  formatCurrency(value) {
    return `$${value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  }
}
